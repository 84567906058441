import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import HeadPanel from '../components/HeadPanel';
import BlogMainSection from '../components/Blog-main-section';
import DiseasesHeader from '../components/Diseases-header';
import { useDiseases } from '../hooks/diseases';
import { useFiles } from '../hooks/files';

const BlogPage = () => {
  const diseases = useDiseases();
  const { medicineSlider } = useFiles();
  return (
    <Layout>
      <HeadPanel
        simple
        img={medicineSlider}
        title={'سرطان های رایج'}
      ></HeadPanel>
      <DiseasesHeader />
      <BlogMainSection diseases={diseases}></BlogMainSection>
    </Layout>
  );
};

export default BlogPage;
