import React from 'react';
import styled from 'styled-components';
import SeperetorBottom from './Seperator-bottom';
import { Container } from 'styled-bootstrap-grid';
import ContentHeader from './Content-header';
import { colors } from '../theme';

const SectionLayout = styled.section`
  position: relative;
  background-color: ${colors.gray.headerBackground};
  border-color: ${colors.gray.headerBackground};
  color: ${colors.gray.headerBackground};
  padding-top: 10rem;
`;

const ParagraphContent = styled.p`
  text-align: justify;
  color: ${colors.gray.headerItemColor};
`;

const DiseasesHeader = () => {
  return (
    <SectionLayout>
      <Container>
        <ContentHeader title={'سرطان چیست؟'} />

        <ParagraphContent>
          سرطان بیماری است که در آن سلولهای غیر طبیعی به صورت کنترل نشده تکثیر
          یافته و می توانند بافتهای مجاور را درگیر کنند. سلولهای سرطانی همچنین
          می توانند از طریق جریان خون یا لنف به سایر نقاط بدن گسترش یابند. انواع
          سرطان شامل کارسینوم، سارکوم، لوسمی، لنفوم و سرطان دستگاه اعصاب مرکزی
          است.
        </ParagraphContent>
      </Container>
      <SeperetorBottom />
    </SectionLayout>
  );
};

export default DiseasesHeader;
