import { useStaticQuery, graphql } from 'gatsby';

export const useDiseases = () => {
  const { allWordpressWpDiseases } = useStaticQuery(
    graphql`
      {
        allWordpressWpDiseases(
          filter: { status: { eq: "publish" } }
          sort: { fields: date, order: DESC }
        ) {
          nodes {
            date(formatString: "MMMM DD, YYYY")
            content
            excerpt
            slug
            modified
            title
            status
            wordpress_id
            featured_media {
              wordpress_id
              localFile {
                publicURL
                childImageSharp {
                  fixed(width: 120, height: 80) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                  fluid(maxWidth: 600, maxHeight: 350) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  const posts = allWordpressWpDiseases.nodes;
  return posts;
};
